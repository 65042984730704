import { createApp } from 'vue'
import App from './App.vue'
import fb from './firebaseConfig'
import './registerServiceWorker'
import router from './router'
import store from './store'

let app: any = false

const appAuth = fb.auth.getAuth(fb.app)
fb.auth.onAuthStateChanged(appAuth, () => {
  if (!app) {
    app = createApp(App)
    app.use(store).use(router).mount('#app')
  }
})

import { createStore } from 'vuex'
import { config } from 'vuex-module-decorators'
import BodyModule from '@src/store/modules/BodyModule'
import BreadcrumbsModule from '@src/store/modules/BreadcrumbsModule'

// eslint-disable-next-line import/no-cycle
import auth from './auth'
import settings from './settings'

config.rawError = true

const store = createStore({
  modules: {
    auth,
    settings,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    BodyModule,
    BreadcrumbsModule
  }
})

export default store

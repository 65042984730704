// eslint-disable-next-line no-shadow
enum Getters {
  // Getter types
  GET_ALL = 'getAll',
  GET_AUTH_STATE = 'getAuthState'
}

// eslint-disable-next-line no-shadow
enum Actions {
  // action types
  LOGOUT_FROM_FB = 'logoutFromFb',
  GET_AUTH_USERDATA = 'getAuthData',
  LOGIN_USER = 'loginUser'
}

// eslint-disable-next-line no-shadow
enum Mutations {
  // mutation types
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setUser',
  SET_DATA = 'setData',
  SET_AUTH_READY = 'setError'
}

const authStore = { Getters, Actions, Mutations }
export default authStore

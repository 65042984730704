// eslint-disable-next-line no-shadow
enum Getters {
  // Getter types
  GET_ACTIVE_SUBMENU = 'settings/getActiveSubmenu',
  GET_SUBMENU_DATA = 'settings/getSubmenuData'
}

// eslint-disable-next-line no-shadow
enum Actions {
  // action types
  SET_ACTIVE_SUBMENU = 'settings/setActiveSubmenu',
  SET_SUBMENU_DATA = 'settings/setSubmenuData'
}

// eslint-disable-next-line no-shadow
enum Mutations {
  // mutation types
  SET_ACTIVE_SUBMENU = 'settings/setActiveSubmenu',
  SET_SUBMENU_DATA = 'settings/setSubmenuData'
}

const settingsStore = { Getters, Actions, Mutations }
export default settingsStore

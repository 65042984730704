/*
 * Modified auth.js store from the Metronic Theme
 * Keeping this no-namespaced to optimize theme compability (!important)
 */

import enums from '@src/store/enums/settings'
import removeNamespace from '@src/core/helpers/namespaceHelper'

const _TYPES = removeNamespace('settings/', {
  Getters: enums.Getters,
  Actions: enums.Actions,
  Mutations: enums.Mutations
})
const namespaced = true

console.log('TYPES:', _TYPES)

const state = () => ({
  submenu: {
    name: 'Global',
    data: {}
  }
})

const getters = {
  [_TYPES.Getters.GET_ACTIVE_SUBMENU]: (state: any) => `SubHeader${ state.submenu.name}`,
  [_TYPES.Getters.GET_SUBMENU_DATA]: (state: any) => state.submenu.data
}

const actions = {
  [_TYPES.Actions.SET_ACTIVE_SUBMENU] (state: any, menuname: any) {
    state.commit(_TYPES.Mutations.SET_ACTIVE_SUBMENU, menuname)
  },
  [_TYPES.Actions.SET_SUBMENU_DATA] (state: any, data: any) {
    state.commit(_TYPES.Mutations.SET_SUBMENU_DATA, data)
  }
}

const mutations = {
  [_TYPES.Mutations.SET_ACTIVE_SUBMENU] (state: any, menuname: any) {
    state.submenu.name = menuname
  },
  [_TYPES.Mutations.SET_SUBMENU_DATA] (state: any, data: any) {
    state.submenu.data = data
  }
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}

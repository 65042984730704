import fb from '@src/firebaseConfig'
import store from '@src/store' // eslint-disable-line import/no-cycle
import authStore from '@src/store/enums/auth'
import { Actions as themeActions } from '@src/store/enums/StoreEnums'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@src/layouts/Default.vue'),
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('@src/views/Dashboard.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'tasks',
        path: '/tasks',
        component: () => import('@src/views/tasks/Index.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'products',
        path: '/products',
        component: () => import('@src/views/products/Index.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'productsCreate',
        path: '/products/create',
        component: () => import('@src/views/products/Create.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'productsSynchronize',
        path: '/products/synchronize',
        component: () => import('@src/views/products/Synchronize.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'templates',
        path: '/templates',
        component: () => import('@src/views/templates/Index.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'customers',
        path: '/customers',
        component: () => import('@src/views/customers/Index.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'customersCreate',
        path: '/customers/create',
        component: () => import('@src/views/customers/Create.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'logs',
        path: '/logs',
        component: () => import('@src/views/logs/Index.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@src/layouts/Auth.vue'),
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('@src/views/Login.vue')
      }
    ]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  const auth = fb.auth.getAuth()
  const { currentUser } = auth

  if (currentUser) {
    // User - if a logged in user exists, we need to make sure additional data from Firestore exists
    if (store.getters[authStore.Getters.GET_ALL] === null) {
      store.dispatch(themeActions.LOGIN, { user: currentUser, mode: 'auto' })
    }
  }

  // If login is required
  if (requiresAuth && !currentUser) {
    return next('/login')
  }
  
  // If a logged in user tries to access a non-auth-required-route
  if (!requiresAuth && currentUser) {
    return next('/dashboard')
  }
    
  // On success
  return next()
})

export default router

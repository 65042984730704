/*
 * Modified auth.js store from the Metronic Theme
 * Keeping this no-namespaced to optimize theme compability (!important)
 */

import router from '@src/router' // eslint-disable-line import/no-cycle
import objectPath from 'object-path'
import fb from '@src/firebaseConfig'
import enums from '@src/store/enums/auth'
import { Actions as themeActions, Mutations as themeMutations } from '@src/store/enums/StoreEnums'

const objectPathWIP = objectPath.create({ includeInheritedProps: true })

const state = () => ({
  errors: {},
  user: {}, // Firebase user data (Firebase auth system)
  data: null, // Firestore DB (users) data
  isAuthenticated: false,
  isReady: false,
  test: true
})

const getters = {
  [enums.Getters.GET_ALL] (state: any) {
    return Object.keys(state.user).length > 0 && state.data !== null
      ? {
          user: state.user, // Firebase (auth system)
          data: state.data // Firestore (db)
        }
      : null
  },
  [enums.Getters.GET_AUTH_STATE] (state: any) {
    return state.isReady
  }
}

const actions = {
  async [themeActions.LOGIN] (context: any, data: any) {
    if (data.user !== undefined) {
      console.log('[VUEX :: Auth] Login')

      const authUser = data.user // Firebase Auth system userdata
      // eslint-disable-next-line
      // @ts-ignore
      const mode = objectPathWIP.get(
        data,
        'mode',
        'auto'
      ) === 'manually'
        ? enums.Actions.LOGIN_USER
        : enums.Actions.GET_AUTH_USERDATA
      
      const authData = await context.dispatch(mode) // Firestore DB userdata (users)
      if (authData.errors !== undefined) {
        await context.dispatch(enums.Actions.LOGOUT_FROM_FB) // Logout from Firebase if login fails
        // eslint-disable-next-line
        // @ts-ignore
        if (mode === 'auto') {
          // From anywhere in the app
          context.commit(themeMutations.SET_ERROR, { message: 'custom/missing-user-in-db' }, { root: true })
          router.push('/login')
        } else {
          // From the login-screen
          throw {
            code: 'custom/missing-user-in-db',
            servermsg: authData.errors
          }
        }
      }

      context.commit(enums.Mutations.SET_DATA, authData) // Firestore DB userdata (users)
      context.commit(themeMutations.SET_AUTH, authUser) // Firebase Auth system userdata
      context.commit(enums.Mutations.SET_AUTH_READY) // Login done!

      return {
        auth: authUser,
        data: authData
      }
    }

    console.error('[VUEX] Login: ERROR, user is required ')
    return false
  },
  async [themeActions.LOGOUT] (context: any) {
    console.log('[VUEX :: Auth] Logout')
    context.commit(themeMutations.PURGE_AUTH)
  },
  [enums.Actions.LOGOUT_FROM_FB] (context: any) {
    return new Promise((resolve, reject) => {
      console.log('[VUEX :: Auth] Logout from FB')
      fb.auth.signOut(fb.auth.getAuth()).then(() => {
        resolve(context.dispatch(themeActions.LOGOUT))
      }).catch((err: any) => {
        reject(err)
      })
    })
  },
  /**
   * LOGIN_USER
   * Basically the same as "GET_AUTH_USERDATA", except the cloud-function has some
   * additional login-features
   */
  async [enums.Actions.LOGIN_USER] (context: any) {
    const loginUser = await fb.functions.httpsCallable('backendUserLoginUser')
    return await loginUser().then((res: any) => {
      if (objectPathWIP.has(res, 'data.success')) {
        return res.data.result
      }

      return res.data // including errors
    }).catch((err: any) => {
      console.log('[VUEX :: Auth] ERROR: Could not fetch the user', err)
      context.commit(themeMutations.SET_ERROR, { message: 'custom/firebase-function-error' }, { root: true })
      return err
    })
  },
  async [enums.Actions.GET_AUTH_USERDATA] (context: any) {
    const getUser = await fb.functions.httpsCallable('backendUserGetUser')
    return await getUser().then((res: any) => {
      console.log('Fetching USERDATA:', res)
      if (objectPathWIP.has(res, 'data.success')) {
        return res.data.result
      }

      return res.data // including errors
    }).catch((err: any) => {
      console.log('[VUEX :: Auth] ERROR: Could not fetch the user', err)
      context.commit(themeMutations.SET_ERROR, { message: 'custom/firebase-function-error' }, { root: true })
      return err
    })
  }
}

const mutations = {
  [themeMutations.SET_ERROR] (state: any, error: any) {
    // Vue.set(state, 'errors', error)
    state.errors = error
  },
  [themeMutations.SET_AUTH] (state: any, user: any) {
    state.isAuthenticated = true
    state.user = user
    state.errors = {}
  },
  [enums.Mutations.SET_DATA] (state: any, data: any) {
    // Vue.set(state, 'data', data)
    state.data = data
  },
  [themeMutations.PURGE_AUTH] (state: any) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    state.data = null
    state.isReady = false
  },
  [enums.Mutations.SET_AUTH_READY] (state: any) {
    state.isReady = true
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
